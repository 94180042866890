'use strict'

const service = config.VUE_APP_service_licenze || ''

function getServiceBase() {
  return service
}

async function getRivenditori() {
  try {
    const response = await fetch(`${service}/rivenditori`, {
      headers: { 'Content-Type': 'application/json' }
    })
    return await response.json()
  } catch (error) {
    // errorService.onError(error, 'getRivenditori')
    return []
  }
}

async function getRivenditoreFromLinkRegistrazione(linkRegistrazione) {
  try {
    const response = await fetch(`${service}/rivenditori/link/${linkRegistrazione}`, {
      headers: { 'Content-Type': 'application/json' }
    })
    return await response.json()
  } catch (error) {
    // errorService.onError(error, 'getRivenditori')
    return []
  }
}

async function getModello() {
  const response = await fetch(`${service}/rivenditori/modello`)
  return await response.json()
}

async function creaRivenditore(rivenditore) {
  try {
    const response = await fetch(`${service}/rivenditori/create`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(rivenditore)
    })
    return await response.json()
  } catch (err) {
    return {}
  }
}

async function modificaRivenditore(rivenditore) {
  try {
    const response = await fetch(`${service}/rivenditori/${rivenditore.codice}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(rivenditore)
    })
    return await response.json()
  } catch (err) {
    return {}
  }
}

async function getUtentiRivenditore(idsUtenti) {
  try {
    const data = {
      idsUtenti
    }
    const response = await fetch(`${service}/rivenditori/utenti`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
    return await response.json()
  } catch (error) {
    // errorService.onError(error, 'getRivenditori')
    return []
  }
}

export default {
  getServiceBase,
  getRivenditori,
  getRivenditoreFromLinkRegistrazione,
  getModello,
  creaRivenditore,
  modificaRivenditore,
  getUtentiRivenditore
}
